const SubjectScore = (props) => {
  const data = props.data;
  const grading = props.grading;
  const gradingperclass = props.gradingperclass;

  function convertToNumber(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return '';
    }
    return number;
  }

  function getGradingName(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }

  function getGradingDescription(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }

  const style = {
    width: "30px",
    textAlign: "center",
    height: "8px",
    fontSize: "12px"
  };

  return (
    <>
      <tr>
        <td style={style} >{ data.subject_name }</td>
        <td style={style} >{ convertToNumber(parseInt(data?.first_grade)) }</td>
        <td style={style} >{ convertToNumber(parseInt(data?.second_grade)) }</td>
        <td style={style} >{ convertToNumber(parseInt(data?.third_grade)) }</td>
        <td style={style} >{ convertToNumber(parseInt(data?.fourth_grade)) }</td>
        <td style={style} >{ convertToNumber(data?.exam_grade) }</td>
        <td style={style} >{ convertToNumber(data?.final_grade) }</td>
        <td style={style} >{ data?.grade_name || data?.name }</td>
        <td style={style} >{ data?.grade_description || data?.description }</td>
      </tr>      
    </>
  );
};
export default SubjectScore;