const GradingView = (props) => {
  const data = props.data;
  return (
    <>
      <tr>
        <td>{ data?.grade_minimum_mark || data?.minimum_mark } ‐ { data?.grade_maximum_mark || data?.maximum_mark }</td>
        <td>{ data?.grade_name || data?.name }</td>
        <td>{ data?.grade_description || data?.description }</td>
      </tr>     
    </>
  );
};
export default GradingView;