import { useLocation } from "react-router-dom";
import { useState } from "react";
import GradingView from "./GradingView";
const Otherinfo = (props) => {
  const location = useLocation();
  const data = props.data;
  const grading = props.grading;
  const gradingperclass = props.gradingperclass;
  const teacher = props.teacher;
  let options = props?.options?.options;
  const student_id = location.state.student_id;
  const school_id = location.state.school_id;
  const session_id = location.state.session_id;
  const class_id = location.state.class_id;
  const section_id = location.state.history.section_id;
  const [number_of_absent, setAbsent] = useState('');
  const [number_of_halfday, setHalfDay] = useState('');
  const [number_of_present, setPresent] = useState('');
  const [number_of_total_present, setTotalPresent] = useState('');
  const [number_of_days_school_open, setTotalSchoolOpen] = useState('');
  // console.log(data);

  console.log("options: ");
  console.log(options);
  if (typeof options === 'string') {
    try {
        options = JSON.parse(options);
    } catch (e) {
        options = null;
    }
  }

  if (options === null || options === "" || (typeof options === 'object' && Object.keys(options).length === 0)) {
      options = null;
  }

  console.log(options);

  async function getAttendance(school_id, session_id, class_id, section_id){
    const url = 'https://api.early.school/api/resultdirect/attendance';
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(
        {
          schoolid: school_id,
          sessionid: session_id,
          classid: class_id,
          sectionid: section_id,
          studentid: student_id,
        }
      )
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      if (!data.success){
        console.log(data);
        setTotalSchoolOpen('');
        setAbsent('');
        setHalfDay('');
        setPresent('');
      } else {
        console.log(data);
        setAbsent(data.number_of_absent);
        setHalfDay(data.number_of_halfday);
        setPresent(data.number_of_present);
        setTotalPresent(data.number_of_total_present);
        setTotalSchoolOpen(data.number_of_days_school_open);
      };
    })
    .catch((err) => {
      console.log(err.message);
    });
  }
  getAttendance(school_id, session_id, class_id, section_id);

  console.log('data Otherinfo: ');
  console.log(data);
  const alignstyle = {
    textAlign: "center"
  };
  
  return (
    <>
      <div className="key-to-grade">
      
        { gradingperclass === null|{}|gradingperclass?.kind === 'not_found' ?
           grading === null|{}|grading?.kind === 'not_found' ?
            null
            :
            <>
              <p className="Key">Key to Grades</p>
              <div className="key-to">
                <table className="">
                  <tbody>
                    { grading && grading?.map((data) => 
                      <GradingView 
                        key={data?.id}
                        data={data}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            </>
          :
          <>
            <p className="Key">Key to Grades</p>
            <div className="key-to">
              <table className="">
                <tbody>
                  { gradingperclass && gradingperclass?.map((data) => 
                    <GradingView 
                      key={data.id}
                      data={data}
                    />
                  )}
                </tbody>
              </table>
            </div>
          </>
          }
          <div className="payable-fees">
          { options?.options?.next_term_fees || data?.custom_next_term_textbook_fee !== null ? 
            <div className="next-time-fees">
              <p className="fees">Next Term Fee</p>
              <p className="comment">{ options?.options?.next_term_fees || data?.custom_student_school_fees }</p>
            </div>
          : null }
          { options?.options?.next_term_textbook_fee && data?.custom_student_school_fees !== null ? 
            <div className="next-term-textbook-fees">
              <p className="fees">Next Term Textbook Fee</p>
              <p className="comment">{ options?.options?.next_term_textbook_fee || data?.custom_next_term_textbook_fee }</p>
            </div>
          : null }
          { options?.options?.next_term_resume && data?.custom_school_resume !== null ? 
            <div className="payable">
              <p className="pay">Next Term Resumes</p>
              <p className="comment">{ options?.options.next_term_resume || data?.custom_school_resume }</p>
            </div>
          : null }
          { options?.options?.payable_on_or_before && data?.payable_before !== null ? 
            <div className="payable">
              <p className="pay">Payable on or Before</p>
              <p className="comment">{ options?.options?.payable_on_or_before || data?.payable_before }</p>
            </div>
          : null }
          { options?.options?.no_of_time_school_opened && data?.no_of_times_school_open !== null ? 
            <div className="No-of-time-school-open">
              <p className="sess-sect">No. of time school opened</p>
              <p className="comment">{ options?.options?.no_of_time_school_opened || number_of_days_school_open || data?.no_of_times_school_open }</p>
            </div>
          : null }
          { data?.days_present_in_school !== null ? 
            <div className="total-attendance">
              <p className="sess-sect">Total Attendance</p>
              <p className="comment">{ number_of_total_present || data?.days_present_in_school }</p>
            </div>
          : null }
          { data?.days_present_in_school !== null ? 
            <div className="attendance-in-percent">
              <p className="sess-sect">% Attendance</p>
              <p className="comment">{ number_of_total_present ? parseInt(parseInt(number_of_total_present)/parseInt(number_of_days_school_open)*100) : data.attendance_in_percent }</p>
            </div>
          : null }
        </div>
      </div>
      <div className="flex-comment">
        { data?.teacher_comment !== null ? 
          <div className="class-teacher-comment"><p className="teacher-comment">Class Teacher's Comment</p>{data?.teacher_comment}</div>
        : null }
        { teacher?.first_name !== null ? 
          <div className="class-teacher-name"><p className="teacher-name">Class Teacher's Name</p>{teacher?.first_name} {teacher?.last_name}</div>
        : null }
        { teacher?.teacher_signature !== null ? 
          <div className="signature">
            <p className="sign">Signature</p>
            { teacher?.teacher_signature == null ? null : <img src={ 'https://early.school/cnd/schools_assets/schools_images/teachers_sign_images/'+teacher?.teacher_signature } width="30" height="30" />}
            <div className="commentsss"></div>
          </div>
        : null }
        { data?.head_teacher_comment !== null ? 
          <div className="head-teacher-comment"><p className="teacher-comment">{ options?.options?.use_head_teacher_as_principal_field === '1'? "Principal's" : "Head Teacher's" } Comment</p>{ data?.head_teacher_comment }</div>
         : null }
        { data?.head_teacher_signature !== null ? 
          <div className="signature">
            <p className="sign">Signature</p>
            <div className="commentsss">{ data?.head_teacher_signature }</div>
          </div>
        : null }
          <br></br>
        </div>
    </>
  );
};
export default Otherinfo;